import React from "react";
import "./aboutUs.scss";
import { details } from "../../v2/common/constants/constants";

const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="contact-info">
        <h2>Informații Contact</h2>
        <div className="contact-info__grid">
          <a href="tel:0744365262" className="contact-card">
            <img
              src={`${process.env.PUBLIC_URL}/images/phone.png`}
              alt="Telefon"
              className="icon"
            />
            <h3>Telefon</h3>
            <p>0770 668 208</p>
          </a>
          <a
            href="https://www.facebook.com/CroitoriaCuti"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-card"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/facebookIcon.png`}
              alt="Facebook"
              className="icon"
            />
            <h3>Facebook</h3>
            <p>CroitoriaCuti</p>
          </a>
          <a
            href="https://www.google.com/maps/search/?api=1&query=Strada+Slatioarei+9B+Suceava+Romania"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-card"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/location.png`}
              alt="Locație"
              className="icon"
            />
            <h3>Adresă</h3>
            <p>{details.address}</p>
          </a>
          <div className="contact-card">
            <img
              src={`${process.env.PUBLIC_URL}/images/clock.png`}
              alt="Program"
              className="icon"
            />
            <h3>Program</h3>
            <p>Luni - Vineri: 08:00 - 17:00</p>
            <p>Sâmbătă: 08:00 - 13:00</p>
          </div>
        </div>
      </section>

      <section className="values">
        <h2>Valorile Noastre</h2>
        <div className="values__grid">
          <div className="value-card">
            <h3>Calitate</h3>
            <p>Folosim cele mai bune materiale și tehnici de croitorie</p>
          </div>
          <div className="value-card">
            <h3>Profesionalism</h3>
            <p>Experiență vastă și atenție la detalii</p>
          </div>
          <div className="value-card">
            <h3>Punctualitate</h3>
            <p>Respectăm termenele de livrare promise</p>
          </div>
          <div className="value-card">
            <h3>Personalizare</h3>
            <p>Adaptăm serviciile la nevoile fiecărui client</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
