import React, { useState } from "react";
import "./events.scss";

const Events = () => {
  const ITEMS_PER_PAGE = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const heroBackground = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                     url(${process.env.PUBLIC_URL}/images/events/eventsBackground-min.jpg)`,
  };

  const galleryImages = [
    {
      id: 1,
      src: `${process.env.PUBLIC_URL}/images/events/events1-min.jpg`,
      alt: "Costum Eveniment 1",
    },
    {
      id: 2,
      src: `${process.env.PUBLIC_URL}/images/events/events2-min.jpg`,
      alt: "Costum Eveniment 2",
    },
    {
      id: 3,
      src: `${process.env.PUBLIC_URL}/images/events/events3-min.jpg`,
      alt: "Costum Eveniment 3",
    },
    {
      id: 4,
      src: `${process.env.PUBLIC_URL}/images/events/events4-min.jpg`,
      alt: "Costum Eveniment 4",
    },
    {
      id: 5,
      src: `${process.env.PUBLIC_URL}/images/events/events5-min.jpg`,
      alt: "Costum Eveniment 5",
    },
    {
      id: 6,
      src: `${process.env.PUBLIC_URL}/images/events/events6-min.jpg`,
      alt: "Costum Eveniment 6",
    },
    {
      id: 7,
      src: `${process.env.PUBLIC_URL}/images/events/events7-min.jpg`,
      alt: "Costum Eveniment 7",
    },
    {
      id: 8,
      src: `${process.env.PUBLIC_URL}/images/events/events8-min.jpg`,
      alt: "Costum Eveniment 8",
    },
    {
      id: 9,
      src: `${process.env.PUBLIC_URL}/images/events/events9-min.jpg`,
      alt: "Costum Eveniment 9",
    },
    {
      id: 10,
      src: `${process.env.PUBLIC_URL}/images/events/events10-min.jpg`,
      alt: "Costum Eveniment 10",
    },
    {
      id: 11,
      src: `${process.env.PUBLIC_URL}/images/events/events11-min.jpg`,
      alt: "Costum Eveniment 11",
    },
    {
      id: 12,
      src: `${process.env.PUBLIC_URL}/images/events/events12-min.jpg`,
      alt: "Costum Eveniment 12",
    },
    {
      id: 13,
      src: `${process.env.PUBLIC_URL}/images/events/events13-min.jpg`,
      alt: "Costum Eveniment 13",
    },
    {
      id: 14,
      src: `${process.env.PUBLIC_URL}/images/events/events14-min.jpg`,
      alt: "Costum Eveniment 14",
    },
    {
      id: 15,
      src: `${process.env.PUBLIC_URL}/images/events/events15-min.jpg`,
      alt: "Costum Eveniment 15",
    },
    {
      id: 16,
      src: `${process.env.PUBLIC_URL}/images/events/events16-min.jpg`,
      alt: "Costum Eveniment 16",
    },
    {
      id: 17,
      src: `${process.env.PUBLIC_URL}/images/events/events17-min.jpg`,
      alt: "Costum Eveniment 17",
    },
  ];

  // Calculate total pages
  const totalPages = Math.ceil(galleryImages.length / ITEMS_PER_PAGE);

  // Get current page items
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return galleryImages.slice(startIndex, endIndex);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: document.querySelector(".events__gallery").offsetTop - 100,
      behavior: "smooth",
    });
  };

  // Generate page numbers
  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className="events">
      <section className="events__hero" style={heroBackground}>
        <div className="events__hero-content">
          <h1>Costume pentru evenimente speciale</h1>
          <p>
            Creăm costume elegante și personalizate pentru cele mai importante
            momente din viața dumneavoastră. Fie că este vorba despre o nuntă,
            un botez sau orice altă ocazie specială, vă asigurăm că veți arăta
            impecabil.
          </p>
        </div>
      </section>

      <section className="events__gallery">
        <div className="events__gallery-grid">
          {getCurrentItems().map((image) => (
            <div key={image.id} className="events__gallery-item">
              <img src={image.src} alt={image.alt} loading="lazy" />
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="events__pagination">
            <button
              className={`events__pagination-button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &#8592;
            </button>

            {getPageNumbers().map((number) => (
              <button
                key={number}
                className={`events__pagination-button ${
                  currentPage === number ? "active" : ""
                }`}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </button>
            ))}

            <button
              className={`events__pagination-button ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &#8594;
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default Events;
