import React, { useState } from "react";
import PropTypes from "prop-types";
import "./navbar.scss";
import { labels, paths } from "../../common/constants/constants";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ currentPath }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar__title">
        <Link to={paths.home} onClick={closeMenu}>
          {labels.mainTitle}
        </Link>
      </div>

      <button
        className={`navbar__hamburger ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <div />
        <div />
        <div />
      </button>

      <div className={`navbar__links ${isOpen ? "open" : ""}`}>
        <div className="navbar__links__item">
          <Link
            to={paths.home}
            className={location.pathname === paths.home ? "active" : ""}
            onClick={closeMenu}
          >
            {labels.home}
          </Link>
        </div>
        <div className="navbar__links__item">
          <Link
            to={paths.events}
            className={location.pathname === paths.events ? "active" : ""}
            onClick={closeMenu}
          >
            {labels.events}
          </Link>
        </div>
        <div className="navbar__links__item">
          <Link
            to={paths.products}
            className={location.pathname === paths.products ? "active" : ""}
            onClick={closeMenu}
          >
            {labels.products}
          </Link>
        </div>
        <div className="navbar__links__item">
          <Link
            to={paths.contact}
            className={location.pathname === paths.contact ? "active" : ""}
            onClick={closeMenu}
          >
            {labels.contact}
          </Link>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  currentPath: PropTypes.string,
};

export default Navbar;
