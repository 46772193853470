import React from "react";
import "./footerV2.scss";
import { details } from "../../common/constants/constants";

const Footer = () => (
  <div className="footer">
    <div className="footer__group">
      <img src={`${process.env.PUBLIC_URL}/images/clock.png`} alt="" />
      <div className="form__group__content">{details.workInterval}</div>
    </div>
    <div className="footer__group">
      <img src={`${process.env.PUBLIC_URL}/images/phone.png`} alt="" />
      <div className="form__group__content">
        <a href="tel:0770668208">{details.phoneNumber}</a>
      </div>
    </div>
    <div className="footer__group">
      <img src={`${process.env.PUBLIC_URL}/images/facebookIcon.png`} alt="" />
      <div className="form__group__content">
        <a
          href="https://www.facebook.com/CroitoriaCuti"
          target="_blank"
          rel="noopener noreferrer"
        >
          {details.facebook}
        </a>
      </div>
    </div>
    <div className="footer__group">
      <img src={`${process.env.PUBLIC_URL}/images/email.png`} alt="" />
      <div className="form__group__content">
        <a href="mailto:croitoriacuti@gmail.com">{details.email}</a>
      </div>
    </div>
    <div className="footer__group">
      <img src={`${process.env.PUBLIC_URL}/images/location.png`} alt="" />
      <div className="form__group__content">
        <a
          href="https://www.google.com/maps/search/?api=1&query=Strada+Slatioarei+9B+Suceava+Romania"
          target="_blank"
          rel="noopener noreferrer"
        >
          {details.address}
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
