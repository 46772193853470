import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./app.scss";

import Navbar from "./v2/components/Navbar/Navbar";
import Footer from "./v2/components/Footer/Footer";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Products from "./pages/Products/Products";
import Events from "./v2/pages/Events/Events";

const App = () => {
  const location = useLocation();

  return (
    <div className="app">
      <Navbar currentPath={location.pathname} />
      <main className="app__content">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={AboutUs} />
          <Route path="/products" component={Products} />
          <Route path="/contact" component={AboutUs} />
          <Route path="/portfolio" component={Events} />
          <Redirect from="*" to="/" />
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default App;
