import React, { Component } from "react";
import "./products.scss";
import { filters, products } from "../../v2/common/constants/constants";
import ProductsHeader from "../../components/ProductsHeader/ProductsHeader";
import ProductsFilters from "../../components/ProductsFilters/ProductsFilters";
import ProductsList from "../../components/ProductsList/ProductsList";
import Pagination from "../../components/Pagination/Pagination";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: filters.all,
      filteredProducts: [...products],
      currentPage: 1,
      productsPerPage: 8,
    };
  }

  handleChangeFilterValue = (newValue) => {
    const filteredProducts =
      newValue !== filters.all
        ? products.filter((product) => product.category === newValue)
        : [...products];

    this.setState({
      filterValue: newValue,
      filteredProducts,
      currentPage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ currentPage: pageNumber });
  };

  getCurrentProducts = () => {
    const { currentPage, productsPerPage, filteredProducts } = this.state;
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  };

  render() {
    const { filterValue, filteredProducts, currentPage, productsPerPage } =
      this.state;
    const totalPages = Math.min(
      4,
      Math.ceil(filteredProducts.length / productsPerPage)
    );

    return (
      <div className="products">
        <ProductsHeader />
        <ProductsFilters
          filterValue={filterValue}
          handleChangeFilterValue={this.handleChangeFilterValue}
        />
        <ProductsList products={this.getCurrentProducts()} />
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

export default Products;
